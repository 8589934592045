<template lang="pug">
  div
    base-snackbar(
      :errors="sowPigGetErrors"
    )
    v-data-table(
      :items="sowPigList"
      :headers="headers"
      dense
      single-expand
      :loading="sowPigGetting"
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.sow_number }}
          td {{ item.status }}
          td {{ item.status_last_updated.dateFormat() }}
          td
            v-btn(
              :class="isExpanded ? 'is-expanded' : ''"
              color="blue-grey"
              text
              small
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        td(colspan="4")
          v-container
            v-row
              v-col.text-center(cols="3")
                span.blue-grey--text Service
              v-col.text-center(cols="3")
                span.blue-grey--text Forrowing
              v-col.text-center(cols="3")
                span.blue-grey--text Weaning
              v-col.text-center(cols="3")
                span.blue-grey--text Piglets
            v-divider
            v-row.pa-0(v-for="(item, index) in item.sow_pig_records" :key="index")
              v-col.text-center.pb-0(cols="3")
                small.blue-grey--text {{ (item.service || '').dateFormat() }}
                v-divider
              v-col.text-center.pb-0(cols="3")
                small.blue-grey--text {{ (item.forrowing || '').dateFormat() }}
                v-divider
              v-col.text-center.pb-0(cols="3")
                small.blue-grey--text {{ (item.weaning || '').dateFormat() }}
                v-divider
              v-col.text-right.pb-0(cols="3")
                small.blue-grey--text {{ item.piglets || 'N/A' }}
                v-divider
</template>
style
<script>
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import sowPigRepository from '@/repositories/sow-pig.repository'

const [sowPigGetVars, sowPigGetVarNames] = requestVars({ identifier: 'sow-pig' })
const sowPigGetRequestHandler = new VueRequestHandler(null, sowPigGetVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Sow Number', value: 'sow_number' },
    { text: 'Status', value: 'status' },
    { text: 'Date Update', value: 'status' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'TableSowPig',
  data: () => ({
    ...tableVars(),
    ...sowPigGetVars,
  }),
  created () {
    this.getSowPigs()
  },
  computed: {
    customerId () {
      return this.$route.params.customerId
    },
  },
  methods: {
    getSowPigs () {
      const { customerId } = this.$route.params
      const repository = sowPigRepository.customer
      sowPigGetRequestHandler.setVue(this)
      sowPigGetRequestHandler.execute(repository, [customerId])
    },
  },
}
</script>
<style lang="scss" scop>
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }
</style>